.UserBlob {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
}

.UserBlob #UserBlobPaper {
  background-color: var(--main-bg-bot_user_blob_color);
  padding: 10px;
  margin-top: 8px;
  margin-left: 12vw;
  display: flex;
  color: white;
  font-size: 12px;
  float: right;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
}

.UserTimestamp {
  color: #555555;
  font-size: 10px;
  margin-right: 4px;
  margin-top: 8px;
  float: right;
}

.BotAvatar {
  float: right;
  object-fit: contain;
}

#AvatarStroke {
  stroke: var(--main-bg-bot_avatar_icon_color);
}

#AvatarFill {
  fill: var(--main-bg-bot_avatar_icon_color);
}

#MenuButtonFill {
  fill: var(--main-bg-bot_header_overflow_color);
}

#MenuButtonStroke {
  stroke: var(--main-bg-bot_header_overflow_color);
}

#IconDownFill {
  fill: var(--main-bg-bot_header_expand_color);
}

#DownloadIconFill {
  fill: var(--main-bg-bot_header_download_color);
}

#LinkIconFill {
  fill: var(--main-bg-bot_header_link_color);
}

.BotBlob {
  border-radius: 20px;
  margin-top: 4px;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.BotAvatar {
  width: 64px;
  height: 64px;
  max-height: 43px;
  max-width: 43px;
  float: left;
  position: static;
  vertical-align: middle;
}

.BotBlob #BotBlobPaper {
  background-color: var(--main-bg-bot_blob_color);
  float: left;
  padding: 10px;
  color: #202020;
  font-size: 12px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  max-width: 600px;
}

.BotBlob #BotBlobPaper ul {
  margin-top: 0;
}

.BotBlob .BotBlobOptions {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -8px;

  & .MuiGrid-item {
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: auto;
    max-width: 100%;
    flex-wrap: wrap;
    padding-right: 8px;
  }
}

.BotBlob .BotBlobOption {
  float: left;
  // margin-right: 8px;
  color: var(--main-bg-bot_blob_option_text_color);
  border-radius: 8px;
  background-color: var(--main-bg-bot_blob_color);
  border: 1px solid var(--main-bg-bot_blob_option_border_color);
  font-size: 12px;
  line-height: 14px;
  margin-top: 8px;
  text-transform: none;
  width: 100%;
}

.BotBlobOption text {
  margin: 0;
}

.BotBlobOption:hover {
  background-color: var(--main-bg-bot_blob_option_click_text_color);
  color: var(--main-bg-bot_blob_option_click_text_color);
}

.BotBlob .BotBlobLink {
  float: left;
  color: var(--main-bg-bot_blob_link_text_color);
  border-radius: 8px;
  background-color: white;
  border: 1px solid var(--main-bg-bot_blob_option_border_color);
  font-size: 12px;
  padding: 6px 8px;
  text-decoration: underline;
}

.BotBlob .TypingBotBlob {
  background-color: var(--main-bg-bot_blob_color);
  float: left;
  padding: 8px;
  color: black;
  border-radius: 8px;
  min-width: 50px;
}

.blob-focusable:focus-visible {
  outline: 2px solid var(--main-bg-bot_submit_button_color);
}

#MessageContainer {
  max-width: 600px;
  width: calc(100% - 50px);
}

#BotBlobFeedback {
  float: left;
  margin-right: 8px;
  color: var(--main-bg-bot_blob_option_text_color);
  border-radius: 8px;
  background-color: var(--main-bg-bot_blob_color);
  border: 1px solid var(--main-bg-bot_blob_option_border_color);
  font-size: 12px;
  line-height: 14px;
  margin-top: 8px;
  text-transform: none;
}

.BotBlob #BotBlobExpandPaper {
  background-color: var(--main-bg-bot_blob_color);
  color: #202020;
  font-size: 12px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  white-space: pre-line;
  overflow: hidden;
  padding-top: 4px;
  padding-bottom: 4px;
}

#BotBlobExpand {
  background-color: var(--main-bg-bot_blob_color);
  box-shadow: none;
  color: var(--main-bg-bot_user_blob_color);
}

#expandIcon {
  color: var(--main-bg-bot_user_blob_color);
}

.MuiGrid-root.MuiGrid-item #BotBlopExpandPaper div {
  margin-left: 24px;
}

#beforeexpand {
  padding: 10px 23px 10px 17px;
}

#afterexpand {
  padding: 10px 23px 10px 17px;
}

/*
Removes click ripple effect on entire page
*/
.MuiTouchRipple-root {
  display: none;
}

/* 
Each expand panel/row
*/
#panel1a-header.MuiButtonBase-root.MuiExpansionPanelSummary-root {
  display: flex;
  padding: 2px 24px 2px 10px;
  min-height: 0px;
  transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  justify-content: left;
}

/*
Content inside of panel/row
*/
#panel1a-header.MuiButtonBase-root.MuiExpansionPanelSummary-root
  .MuiExpansionPanelSummary-content {
  max-width: 279px;
  margin: 0px 0;
  display: flex;
  flex-grow: 1;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiButtonBase-root.MuiIconButton-root.MuiExpansionPanelSummary-expandIcon.MuiIconButton-root {
  margin-left: 40px;
  padding: 2px 0px;
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
}

#BotBlobExpandDetail {
  color: #202020;
  display: flex;
  padding: 0px 18px 0px 10px;
}

#BotBlobExpandPaper .MuiGrid-root {
  &:last-child #panel1a-content {
    padding-bottom: 4px;
  }
}

#typingDot {
  font-size: 11px;
}

.BotBlob .Blinking {
  font-size: 20px;
}

.BotBlobImg {
  width: 100%;
  object-fit: contain;
  max-width: 360px;
}

/* If there is no title / descr with image, below design is used. */
.responseImg {
  margin-top: 8px;
  border-radius: 8px;
  margin-right: 8px;
}

/* If title / descr is given with image, below design is used. */
.responseImgWithText {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-right: 8px;
}

/* Text below image */
.responseImgText {
  max-width: 360px;
  width: 100%;
  background-color: var(--main-bg-bot_blob_color);
  padding-bottom: 10px;
  color: #202020;
  font-size: 12px;
  margin-top: -6px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

iframe {
  border: 0;
}

.player {
  position: inherit;
}

.responseVid {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  object-fit: contain;
  width: 100%;
  max-width: 360px;
}

.responseVidText {
  width: 100%;
  max-width: 360px;
  background-color: var(--main-bg-bot_blob_color);
  float: left;
  padding-bottom: 10px;
  color: #202020;
  font-size: 12px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.responseTextAlign {
  padding-left: 15px;
  padding-top: 10px;
  color: #202020;
}

#timestamp {
  color: #555555;
  font-size: 10px;
  margin-left: 4px;
  margin-top: 8px;
}

#LinkButton {
  height: 100%;
}

#linkIconLink svg {
  height: 100%;
  min-width: 10px;
  top: 0;
  min-height: 10px;
  margin-left: 5px;
}

/* Safari 7.1+ */

@media not all and (min-resolution: 0.001dpcm) {
  #linkIconLink svg {
    margin-top: 10%;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  #linkIconLink svg {
    max-height: 30px !important;
  }
  .BotBlobResize {
    flex-basis: auto;
  }

  #MessageContainer {
    width: 100vw;
  }

  .BotBlobImg {
    width: 100vw;
  }

  #BotBlobExpandPaper {
    width: 100%;
  }
}

@keyframes blink {
  /**
   * At the start of the animation the dot
   * has an opacity of .2
   */
  0% {
    opacity: 0.2;
  }
  /**
   * At 20% the dot is fully visible and
   * then fades out slowly
   */
  20% {
    opacity: 1;
  }
  /**
   * Until it reaches an opacity of .2 and
   * the animation can start again
   */
  100% {
    opacity: 0.2;
  }
}

.Blinking span {
  /**
   * Use the blink animation, which is defined above
   */
  animation-name: blink;
  /**
   * The animation should take 1.4 seconds
   */
  animation-duration: 1.4s;
  /**
   * It will repeat itself forever
   */
  animation-iteration-count: infinite;
  /**
   * This makes sure that the starting style (opacity: .2)
   * of the animation is applied before the animation starts.
   * Otherwise we would see a short flash or would have
   * to set the default styling of the dots to the same
   * as the animation. Same applies for the ending styles.
   */
  animation-fill-mode: both;
}

.Blinking span:nth-child(2) {
  /**
   * Starts the animation of the third dot
   * with a delay of .2s, otherwise all dots
   * would animate at the same time
   */
  animation-delay: 0.2s;
}

.Blinking span:nth-child(3) {
  /**
   * Starts the animation of the third dot
   * with a delay of .4s, otherwise all dots
   * would animate at the same time
   */
  animation-delay: 0.4s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
