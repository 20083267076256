.App {
  height: 100%;
  align-items: center;
  margin: auto;
  background-color: transparent;
}
/* override material styles to use vars */

:root {
  --main-bg-bot_header_color: #7F7B73;

  --main-bg-bot_blob_color: #F1F0F0;
  --main-bg-bot_user_blob_color: #7F7B73;

  --main-bg-bot_blob_link_text_color: #7F7B73;

  --main-bg-bot_blob_option_text_color: #202020;
  --main-bg-bot_blob_option_click_text_color: #F1F0F0;
  --main-bg-bot_blob_option_background_color: #7F7B73;
  --main-bg-bot_blob_option_border_color: #7F7B73;

  --main-bg-bot_input_box_color: #fdfcf8;
  --main-bg-bot_submit_button_color: #7F7B73;
  --main-bg-bot_submit_button_text: #F1F0F0;

  /* Icons */
  --main-bg-bot_header_link_color: #7F7B73;
  --main-bg-bot_header_download_color: #7F7B73;
  --main-bg-bot_header_expand_color: #7F7B73;
  --main-bg-bot_header_overflow_color: #F1F0F0;

  --main-bg-bot_avatar_color: #7F7B73;
  --main-bg-bot_avatar_icon_color: #7F7B73;

  --main-bg-bot_primary_color: #7F7B73;
  --main-bg-bot_secondary_color: #7F7B73;

}


:root {
  .MuiButton-root {
    color: var(--main-bg-bot_primary_color);
  }

  .MuiButton-textPrimary {
    color: var(--main-bg-bot_primary_color);
  }

  .MuiButton-textSecondary {
    color: var(--main-bg-bot_secondary_color);
    opacity: 0.6;
  }

  .MuiInput-underline::after {
    color: var(--main-bg-bot_secondary_color);
    opacity: 0.6;
    border-bottom-color: var(--main-bg-bot_secondary_color);
    opacity: 0.6;
    border-bottom-style: solid;
    border-bottom-width: 2px;
  }

  .Mui-focused .MuiFormHelperText {
    color: var(--main-bg-bot_secondary_color);
    opacity: 0.6;
  }

  /*.MuiInput-underline{
    color: var(--main-bg-bot_secondary_color);opacity:0.6;
  }*/

  .MuiFormLabel-root.Mui-focused {
    color: var(--main-bg-bot_primary_color);
  }

  .MuiRating-root {
    color: var(--main-bg-bot_submit_button_color) !important;
  }
  .MuiExpansionPanelSummary-content{
    max-width: 100% !important;
  }

}
