.container {
  max-width: 100%;
}
.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 600;
}
.link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 400;
  color: #828181;
  font-size: 10px;
  line-height: 14px;
  margin-top: 2px;
  margin-bottom: 4px;
}
.passage {
  position: relative;
}
.passage em {
  position: relative;
  // padding: 0px 1px;
  // background-color: var(--main-bg-bot_blob_link_text_color);
  // color: var(--main-bg-bot_blob_color);
  // margin: -0px -1px;
  font-weight: 500;
}
