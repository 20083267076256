.grid-header {
  display: block;
  height: 55px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;
  flex-shrink: 0;
}

.grid-center {
  display: block;
  flex: 1 1 auto;
  -webkit-box-flex: 1;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
}

.grid-footer {
  flex-shrink: 0;
  display: block;
  background: white;
  z-index: 1;
}

.container {
  font-family: Arial, sans-serif;
  background-color: white;
  border-radius: 8px;
  height: 100vh;
  width: 100vw;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.chatLog {
  overflow: hidden;
  white-space: pre-wrap;
  height: fit-content;
  width: 100%;
  z-index: 0;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 5px;
}

.chatLog ul,
.chatLog ol {
  padding: 0;
  width: 100%;
  margin: 0;
  li {
    transition: 1s;
    margin: 4px 15px 0px 15px;
    width: calc(100% - 25px);
    float: left;
    &:last-child {
      margin-bottom: 4px;
    }
  }
}

.chatLog .sent {
  float: right;
  width: 100%;
}

.chatLog .sent img {
  float: right;
  width: auto;
  margin: 3px 0 0 8px;
  filter: brightness(1);
}

.chatLog .replies img {
  filter: brightness(0.7);
}

.chatLog .bubble {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 8px;
  line-height: 130%;
  margin-bottom: 5px;
  max-width: 43%;
  box-shadow: 5px 5px 15px darkgray;
}

.chatLog .ui.loader:after {
  border-color: white transparent transparent;
}

.scrollableLog {
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  /*margin-left: 15px;
  margin-right: 15px;*/
}

#mainDiv {
  height: 100%;
}
