.wrapper {
  position: fixed;
  top: 56px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 99;
}

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #F6F6F6;
  border-top: 1px solid var(--main-bg-bot_header_color);
  padding: 24px;
  min-height: 200px;
}

.input {
  box-sizing: border-box;
  background-color: white;
  border: 1px solid var(--main-bg-bot_header_color);
  border-radius: 3px;
  padding: 12px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 100%;
  margin-top: 8px;
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  &::placeholder {
    color: #7D7D7D;
  }
  &:focus {
    outline: 1px solid var(--main-bg-bot_header_color);
  }
}

.container .submitBtn {
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  min-width: 55px;
  height: 48px;
  padding: 12px 25px 12px 25px;
  background-color: var(--main-bg-bot_submit_button_color);
  color: var(--main-bg-bot_submit_button_text);
  font-size: 14px;
  border-radius: 3px;
  font-family: IBM Plex Sans;
  text-transform: none;
  border: none !important;
  float: right;
  &:hover {
    background-color: var(--main-bg-bot_submit_button_color);
    color: var(--main-bg-bot_submit_button_text);
  }
  
  &:active {
    background-color: var(--main-bg-bot_submit_button_color);
    color: var(--main-bg-bot_submit_button_text);
    opacity: 0.9;
  }
  
  &:focus {
    background-color: var(--main-bg-bot_submit_button_color);
    color: var(--main-bg-bot_submit_button_text);
  }
}

.container .cancelBtn {
  box-sizing: border-box;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 500;
  text-decoration: underline;
  text-transform: none;
  color: black;
  &:hover {
    text-decoration: underline;
  }
}