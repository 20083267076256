.TextField {
  width:100%;
}

.Divider{
  height: 1px;
  width: 100%;
  background-color: var(--main-bg-bot_submit_button_color);
}

.inputBox {
  display: flex;
  margin-bottom: 0;
  bottom:0;
  background-color: #F6F6F6;
  padding: 12px 20px;
  border-top: 1px solid #EBEBEB;
  .input-wrapper {
    &::before {
      content: "";
      z-index: 1;
      display: block;
      position: absolute;
      border-radius: 5px;
      top: -3px;
      bottom: -3px;
      left: -3px;
      right: -3px;
      background-color: var(--main-bg-bot_submit_button_color);
      opacity: 0;
      transition: 100ms ease-in-out opacity;
    }
    &:focus-within {
      &::before {
        background-color: var(--main-bg-bot_submit_button_color);
        opacity: 0.25;
      }
    }
  }
}

.inputBox .userInput {
  z-index: 2;
  position: relative;
  box-sizing: border-box;
  font-size: 12px;
  color: #32465a;
  outline: none;
  width: 100%;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 48px;
  padding-left: 10px;
  padding-right: 10px;
  border: 2px solid var(--main-bg-bot_submit_button_color);
  border-right: none;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: white;
  transition-property: border-color;
  transition: 100ms ease-in-out;
  &:focus {
    border-color: var(--main-bg-bot_submit_button_color);
  }
}

.inputBox .submit {
  box-sizing: border-box;
  flex-shrink: 0;
  display: flex;
  min-width: 55px;
  height: 48px;
  padding: 12px 25px 12px 25px;
  background-color: var(--main-bg-bot_submit_button_color);
  color: var(--main-bg-bot_submit_button_text);
  font-size: 14px;
  font-family: IBM Plex Sans;
  text-transform: none;
  border: none !important;
  float: right;

  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .inputBox .submit {
    min-width:100px !important;
  };
}

.inputBox .submit:hover {
  background-color: var(--main-bg-bot_submit_button_color);
  color: var(--main-bg-bot_submit_button_text);
}

.inputBox .submit:active {
  background-color: var(--main-bg-bot_submit_button_color);
  color: var(--main-bg-bot_submit_button_text);
}

.inputBox .submit:focus {
  background-color: var(--main-bg-bot_submit_button_color);
  color: var(--main-bg-bot_submit_button_text);
}

::-ms-clear {
  display: none;
}