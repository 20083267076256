.content-header {
  display: flex;
  flex-grow: 5;
  /*
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  -webkit-border-top-left-radius: 8px;
  -webkit-border-top-right-radius: 8px;
  -moz-border-radius-topleft: 8px;
  -moz-border-radius-topright: 8px;*/
  touch-action: none;
  background: var(--main-bg-bot_header_color);
  color: white;
  align-items: center;
  font-size: 1.5rem;
  height: 55px;
}

.MenuButton {
  padding: 17.5px 25px !important;
}

.OverflowMenu {
  height: 20px;
}

.OverflowMenu .Menu-overflow {
  fill: var(--main-bg-bot_header_color);
}

.CloseIcon {
  color: white;
}

.CloseIconButton {
  float: right;
}

.chatbotName {
  height: 20px;
  width: 80%;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
}