body {
  margin: 0;
  font-family: 'IBM Plex Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;

  /* Fix Safari iframe width calc */
  width: 1px;
  min-width: 100%;
  height: 100%;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

.mainDiv {
  height: 100%;
  overflow: hidden;
}


@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  body {
    -ms-overflow-style: none;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  color: var(--main-bg-bot_blob_link_text_color)
}

.sr-only {
  position:absolute;
  left:-10000px;
  top:auto;
  width:0px;
  height:0px;
  overflow:hidden;
}